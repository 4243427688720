import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/:venue/:secret',
    redirect: to => {
      return { name: 'Tresen', params: to.params }
    }
  },
  {
    path: '/:venue/:secret/orders',
    name: 'Tresen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "orders" */ '../views/Orders.vue')
    }
  },
  {
    path: '/:venue/:secret/items/:station',
    name: 'Station',
    component: function () {
      return import(/* webpackChunkName: "items" */ '../views/Items.vue')
    }
  },
  {
    path: '/:venue/:secret/dishes',
    name: 'Dishes',
    component: function () {
      return import(/* webpackChunkName: "dishes" */ '../views/Dishes.vue')
    }
  },
  {
    path: '/:venue/:secret/history',
    name: 'History',
    component: function () {
      return import(/* webpackChunkName: "orders" */ '../views/History.vue')
    }
  },
  {
    path: '/:venue/:secret/brands',
    name: 'Brands',
    component: function () {
      return import(/* webpackChunkName: "brands" */ '../views/Brands.vue')
    }
  },
  {
    path: '/:venue/:secret/integration',
    name: 'Integration',
    component: function () {
      return import(/* webpackChunkName: "brands" */ '../views/Integration.vue')
    }
  },
  {
    path: '/:venue/:secret/modifiers',
    name: 'Modifiers',
    component: function () {
      return import(/* webpackChunkName: "modifiers" */ '../views/Modifiers.vue')
    }
  },
  {
    path: '/:venue/:secret/stations',
    name: 'Stations',
    component: function () {
      return import(/* webpackChunkName: "stations" */ '../views/Stations.vue')
    }
  },
  {
    path: '/:venue/:secret/modifiers',
    name: 'Modifiers',
    component: function () {
      return import(/* webpackChunkName: "modifiers" */ '../views/Modifiers.vue')
    }
  }


]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
