import dayjs from 'dayjs'
dayjs.extend(require('dayjs/plugin/duration'))
dayjs.extend(require('dayjs/plugin/relativeTime'))
import 'dayjs/locale/de'
dayjs.locale('de')

var intl = Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

export default {
  methods: {
    formatDatetime (date) {
      return dayjs(date).format('DD.mm.YYYY HH:mm:ss.SSS')
    },
    formatDate (date) {
      return dayjs(date).format('dddd, DD. MMMM YYYY')
    },
    formatTime (date, seconds=false) {
      return dayjs(date).format(seconds ? 'HH:mm:ss' : 'HH:mm')
    },
    formatTimer(duration) {
      var f, prefix
      if (duration < 0) {
        duration = 0 - duration
        prefix = '-'
      } else {
        prefix = ''
      }
      if (duration >= 3600000)
        f = 'H:mm:ss'
      else
        f = 'm:ss'
      return prefix + dayjs.duration(duration).format(f)
    },
    formatGuess(duration) {
      if (duration < 0) {
        duration = 0 - duration
        return dayjs.duration(duration).humanize() + ' zu spät'
      }
      return dayjs.duration(duration).humanize()
    },
    as_eur(amount) {
      return intl.format(amount * 0.01) + " €"
    },
    as_percentage(percentage) {
      return percentage * 0.1
    }
  }
}

