import { createStore } from 'vuex'

export default createStore({
  state: {
  },
  mutations: {
    update (state, newState) {
      // TODO: allow updating only single elements
      Object.assign(state, newState)
    }
  },
  actions: {
  },
  modules: {
  }
})
